import $ from 'jquery';

$(function () {
  // Wait when page is loaded and then import module dynamically
  // because work with DOM performs in the constructor
  // so DOM should be loaded before run this module
  import('../app/Login.js')
    .then(ConfirmPassword => {
      // All needed work was done in the constructor
    })
    .catch(err => {
      console.error(err)
    })
});
